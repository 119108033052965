var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('CCard',{staticClass:"h-100"},[_c('CCardBody',{staticClass:"technician-form"},[_c('div',{staticClass:"shop__header"},[(!_vm.isUpdate)?_c('h5',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("EditorTitle.NewEditor"))+" ")]):_vm._e(),(_vm.isUpdate)?_c('h5',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.capitalize(_vm.editorForm.name))+" ")]):_vm._e()]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"custom"},[_c('div',{staticClass:"custom__input"},[_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"editor_name","rules":("requiredExport|max:" + (_vm.EDITOR_RULE.NAME))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t("EditorFormFields.Name")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.editorForm.name),expression:"editorForm.name",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"name","type":"text","placeholder":_vm.$t('EditorFormFields.Name'),"autocomplete":"name","maxlength":_vm.EDITOR_RULE.NAME},domProps:{"value":(_vm.editorForm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editorForm, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{ref:"nickname",attrs:{"name":"editor_nickname","rules":("requiredExport|max:" + (_vm.EDITOR_RULE.NICKNAME))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t("EditorFormFields.Nickname")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.editorForm.nickname),expression:"editorForm.nickname",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"nickname","type":"text","placeholder":_vm.$t('EditorFormFields.Nickname'),"autocomplete":"nickname","disabled":_vm.isUpdate,"maxlength":_vm.EDITOR_RULE.NICKNAME},domProps:{"value":(_vm.editorForm.nickname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editorForm, "nickname", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"editor_paysheet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t("EditorFormFields.Paysheet")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('multiselect',{attrs:{"id":"paysheet","options":_vm.paysheets,"label":"name","track-by":"id","close-on-select":true,"show-labels":false,"allow-empty":true,"searchable":true,"placeholder":_vm.$t('Label.paysheet')},on:{"select":_vm.selectPaysheet,"remove":_vm.clearPaysheet},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("DropDown.NoOptions"))+" ")]},proxy:true},{key:"noResult",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("DropDown.NoResult"))+" ")]},proxy:true}],null,true),model:{value:(_vm.editorForm.paysheet),callback:function ($$v) {_vm.$set(_vm.editorForm, "paysheet", $$v)},expression:"editorForm.paysheet"}},[_c('template',{slot:"clear"},[(_vm.editorForm.paysheet)?_c('div',{staticClass:"multiselect__clear",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clearPaysheet.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'times'],"title":_vm.$t('Action.Clear')}})],1):_vm._e()])],2),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)])]}}],null,true)})],1)])]),_c('CRow',{staticClass:"text-right"},[_c('CCol',[_c('button',{staticClass:"btn btn-outline-danger",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("Button.Cancel"))+" ")]),(!_vm.isUpdate)?[_c('button',{staticClass:"px-4 ml-2 btn btn-outline-custom",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Button.Create"))+" ")])]:[_c('button',{staticClass:"px-4 ml-2 btn btn-outline-custom",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Button.Update"))+" ")])]],2)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }