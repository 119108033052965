<template>
	<div class="h-100">
		<CCard class="h-100">
			<CCardBody class="technician-form">
				<div class="shop__header">
					<h5 v-if="!isUpdate" class="mb-3">
						{{ $t("EditorTitle.NewEditor") }}
					</h5>
					<h5 v-if="isUpdate" class="mb-3">
						{{ capitalize(editorForm.name) }}
					</h5>
				</div>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitForm)">
						<div class="custom">
							<div class="custom__input">
								<!-- editor name  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="editor_name"
										:rules="`requiredExport|max:${EDITOR_RULE.NAME}`"
									>
										<div class="form-group required row">
											<label for="type" class="col-md-2 col-12 col-form-label">{{
												$t("EditorFormFields.Name")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<input
													id="name"
													v-model.trim="editorForm.name"
													type="text"
													class="form-control"
													:placeholder="$t('EditorFormFields.Name')"
													autocomplete="name"
													:maxlength="EDITOR_RULE.NAME"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- editor nickname  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="nickname"
										name="editor_nickname"
										:rules="`requiredExport|max:${EDITOR_RULE.NICKNAME}`"
									>
										<div class="form-group required row">
											<label for="type" class="col-md-2 col-12 col-form-label">{{
												$t("EditorFormFields.Nickname")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<input
													id="nickname"
													v-model.trim="editorForm.nickname"
													type="text"
													class="form-control"
													:placeholder="$t('EditorFormFields.Nickname')"
													autocomplete="nickname"
													:disabled="isUpdate"
													:maxlength="EDITOR_RULE.NICKNAME"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- editor paysheet  -->
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="editor_paysheet">
										<div class="form-group row">
											<label for="type" class="col-md-2 col-12 col-form-label">{{
												$t("EditorFormFields.Paysheet")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<multiselect
													id="paysheet"
													v-model="editorForm.paysheet"
													:options="paysheets"
													label="name"
													track-by="id"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:searchable="true"
													:placeholder="$t('Label.paysheet')"
													@select="selectPaysheet"
													@remove="clearPaysheet"
												>
													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
													<template slot="clear">
														<div
															v-if="editorForm.paysheet"
															class="multiselect__clear"
															@mousedown.prevent.stop="clearPaysheet"
														>
															<font-awesome-icon
																:icon="['fas', 'times']"
																:title="$t('Action.Clear')"
															/>
														</div>
													</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>

						<CRow class="text-right">
							<CCol>
								<button type="button" class="btn btn-outline-danger" @click="cancel">
									{{ $t("Button.Cancel") }}
								</button>
								<template v-if="!isUpdate">
									<button class="px-4 ml-2 btn btn-outline-custom" type="submit">
										{{ $t("Button.Create") }}
									</button>
								</template>
								<template v-else>
									<button class="px-4 ml-2 btn btn-outline-custom" type="submit">
										{{ $t("Button.Update") }}
									</button>
								</template>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { EDITOR_RULE, PARAMS } from "../../../shared/plugins/constants"
import { SET_MESSAGE, SET_STATE } from "../store/mutation-types"
import { capitalize } from "lodash-es"
import { createNamespacedHelpers } from "vuex"
import { GET_PAYSHEETS } from "../../paysheet/store/action-types"
const { mapState, mapMutations } = createNamespacedHelpers("editors")
const { mapActions: paysheetActions, mapState: paysheetState } = createNamespacedHelpers("paysheet")
export default {
	name: "EditorForm",
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			EDITOR_RULE,
			editor_id: Number(new Date()),
			limit: PARAMS.LIMIT,
			offset: PARAMS.OFFSET,
			capitalize,
			editorForm: {
				name: null,
				nickname: null,
				paysheet: null,
			},
		}
	},
	computed: {
		...mapState(["messageErr", "editorDetail"]),
		...paysheetState(["paysheets"]),
	},
	watch: {
		messageErr(val) {
			if (val) return this.setFormFieldErr("nickname", val)
		},
		editorDetail(val) {
			if (val) this.setupData()
		},
	},
	created() {
		this.setupData()
	},
	methods: {
		...mapMutations({ SET_MESSAGE, SET_STATE }),
		...paysheetActions({ GET_PAYSHEETS }),
		setupData() {
			if (this.isUpdate && this.editorDetail) {
				this.editorForm = { ...this.editorForm, ...this.editorDetail }
				if (this.editorDetail.paysheetId && this.editorDetail.paysheetName) {
					this.editorForm.paysheet = {
						name: this.editorDetail.paysheetName,
						id: this.editorDetail.paysheetId,
					}
				}
			}
			this.getPaysheets()
		},
		async getPaysheets(isLoadMore) {
			const params = {
				data: {},
				notLoading: true,
			}
			if (isLoadMore) {
				params["isLoadMore"] = isLoadMore
			}
			const res = await this.GET_PAYSHEETS(params)
			return res
		},
		submitForm() {
			this.$refs.nickname.reset()
			this.SET_MESSAGE(null)
			const params = {
				name: this.editorForm.name,
				nickname: this.editorForm.nickname,
			}
			if (this.editorForm.paysheet) {
				params["paysheetId"] = this.editorForm.paysheet.id
			}
			if (this.isUpdate) {
				params["id"] = this.id
			}
			this.$emit("submit", params)
		},
		cancel() {
			this.$emit("cancel")
		},
		selectPaysheet(selected) {
			this.editorForm.paysheet = selected
		},
		clearPaysheet() {
			this.editorForm.paysheet = null
		},
		setFormFieldErr(field, err) {
			if (this.$refs[field]) this.$refs[field].setErrors([err])
		},
	},
}
</script>

<style lang="scss" scoped>
.custom__input {
	width: 100%;
}
</style>
